import React, { useState } from "react"
import { motion } from "framer-motion"

const AnimatedPDF = ({ ...other }) => {
  const [onHover, setOnHover] = useState(false)

  return (
    <div
      {...other}
      className="h-full  bg-red"
      onMouseEnter={() => setOnHover(true)}
      onMouseLeave={() => setOnHover(false)}
    >
      <div className="p-[10px] relative flex flex-col items-center justify-center">
        <motion.div
          className="z-0"
          initial={{ scale: 1, y: 0 }}
          animate={{ scale: onHover ? 1.4 : 1, y: onHover ? "2px" : 0 }}
          transition={{
            duration: 0.2,
            delay: 0.2,
            scale: { duration: 0.3 },
          }}
        >
          <svg
            width="10"
            height="5"
            viewBox="0 0 10 5"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.875 4.875V3.375H6.875C6.65 3.375 6.5 3.225 6.5 3V0H0.5C0.275 0 0.125 0.15 0.125 0.375V4.875H9.875Z"
              fill="white"
            />
          </svg>
        </motion.div>

        <motion.div
          className="h-[5px] w-[12px] overflow-hidden z-[10]"
          initial={{ scale: 1, y: 0 }}
          animate={{ scale: onHover ? 1.6 : 1 }}
          transition={{
            duration: 0.2,
            delay: 0.2,
            scale: { duration: 0.3 },
          }}
        >
          <svg
            width="12"
            height="5"
            viewBox="0 0 12 5"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="12" height="5" rx="0.5" fill="white" />
            <path
              d="M4.35 2.53755C4.2 2.65005 4.0125 2.72505 3.75 2.72505H3.6V3.43755H3V1.30005H3.75C4.0125 1.30005 4.2375 1.37505 4.35 1.48755C4.4625 1.60005 4.5375 1.78755 4.5375 1.97505C4.5375 2.20005 4.5 2.38755 4.35 2.53755Z"
              fill="#A71930"
            />
            <path
              d="M6.575 3.13755C6.3875 3.32505 6.08749 3.43755 5.74999 3.43755H5.075V1.30005H5.825C6.1625 1.30005 6.42499 1.37505 6.61249 1.56255C6.79999 1.75005 6.87499 1.97505 6.87499 2.31255C6.87499 2.68755 6.7625 2.95005 6.575 3.13755Z"
              fill="#A71930"
            />
            <path
              d="M8.89999 1.75005H8.18749V2.16255H8.825V2.61255H8.18749V3.43755H7.62499V1.30005H8.89999V1.75005Z"
              fill="#A71930"
            />
            <path
              d="M3.654 1.7688H3.504V2.2563H3.6165C3.6915 2.2563 3.7665 2.2188 3.8415 2.1813C3.9165 2.1438 3.9165 2.0688 3.9165 1.9938C3.9165 1.8438 3.8415 1.7688 3.654 1.7688Z"
              fill="white"
            />
            <path
              d="M5.79999 1.7688H5.64999V2.9688H5.76249C5.94999 2.9688 6.0625 2.9313 6.1375 2.8188C6.2125 2.7063 6.24999 2.5563 6.24999 2.3688C6.24999 2.1813 6.2125 2.0313 6.1375 1.9188C6.0625 1.8063 5.98749 1.7688 5.79999 1.7688Z"
              fill="white"
            />
          </svg>
        </motion.div>

        <motion.div
          className="absolute bottom-0 left-0 top-[-9px] right-[-8px] flex items-center justify-center"
          animate={{
            scale: onHover ? 1.8 : 1.1,
            rotate: onHover ? -45 : 0,
            y: onHover ? ["0px", "-2px", "0px", "-2px", "0px"] : "0px",
            x: onHover ? "1px" : 0,
          }}
          transition={{
            duration: 0.3,
            delay: 0.3,
            scale: { duration: 0.3 },
            y: {
              delay: 0.1,
              repeat: onHover && Infinity,
              repeatType: "loop",
            },
            x: { delay: 0.1 },
            rotate: { delay: 0.2 },
          }}
        >
          <svg
            width="3"
            height="3"
            viewBox="0 0 3 3"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.25 0.605469V2.63047H2.275L0.25 0.605469Z"
              fill="white"
            />
          </svg>
        </motion.div>
      </div>
    </div>
  )
}

export default AnimatedPDF
