import { motion, useAnimation } from "framer-motion"
import React, { useEffect } from "react"

function AnimatedDownload({ onHover }) {
  const controls = useAnimation()

  const arrowVariants = {
    initial: { y: 0 },
    animate: {
      y: [0, -10],
      transition: {
        duration: 0.525,
        ease: "easeInOut",
        delay: 0,

        repeat: Infinity,
        repeatType: "reverse",
      },
    },
  }
  const lineVariants = {
    initial: { x: 0 },
    animate: {
      x: [0, -30, 0, 30, 0],
      transition: {
        duration: 2.1,
        delay: 0,
        ease: "easeInOut",

        repeat: Infinity,
        repeatType: "loop",
      },
    },
  }

  useEffect(() => {
    if (onHover) {
      controls.start("animate")
    } else if (onHover === false) {
      controls.start("initial")
    }
  }, [controls, onHover])

  return (
    <div className={"bg-red h-[32px] w-[33px] overflow-hidden"}>
      <div className="flex flex-col items-center justify-center p-3 ">
        <motion.svg
          initial="initial"
          variants={arrowVariants}
          animate={controls}
          width="7"
          height="8"
          viewBox="0 0 7 8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3.15056 0.407227V6.29039L1.52351 4.78775L0.865234 5.40046L3.60994 7.95517L6.35465 5.40046L5.69637 4.78775L4.08199 6.29039V0.407227H3.15056Z"
            fill="white"
          />
        </motion.svg>

        <motion.div
          initial="initial"
          variants={lineVariants}
          animate={controls}
          className="bg-white w-[6.86px] h-[0.69px] mt-[1px]"
        ></motion.div>
      </div>
    </div>
  )
}

export default AnimatedDownload
