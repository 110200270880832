import { graphql } from "gatsby"
import React, { useState } from "react"
import AnimatedDownload from "~/components/animations/AnimatedDownload"
import AnimatedPDF from "~/components/animations/AnimatedPDF"
import AnimatedPrestentation from "~/components/animations/AnimatedPresentation"
import AnimatedWebcast from "~/components/animations/AnimatedWebcast"
import {
  Section,
  SectionProps,
} from "~/components/FlexibleLayouts/elements/Section"
import {
  CoreCopyStack,
  CoreCopyStackProps,
} from "~/components/FlexibleLayouts/elements/Typography"
import VideoModal from "~/components/VideoModal"
import { Icon, IconType } from "../elements/Icons"

export interface DownloadListProps {
  section: SectionProps
  coreCopyStack: CoreCopyStackProps
  downloads: any[]
  hideColumnNames: boolean
  layout: string
}

export const DownloadList = ({
  coreCopyStack,
  downloads,
  section,
  hideColumnNames,
  layout,
}: DownloadListProps) => {
  const [activeOption, setActiveOption] = useState(0)
  console.log(hideColumnNames);
  return (
    <Section {...section}>
      {coreCopyStack.heading && (
        <div
          className={`max-w-[455px] ${
            coreCopyStack.text ? "mb-12 md:mb-[95px] " : "mb-12"
          } `}
        >
          <CoreCopyStack {...coreCopyStack} />
        </div>
      )}

      <div className="">
        <div className="flex items-center justify-between">
          {downloads?.length > 1 && (
            <form action="" className="relative">
              <select
                className={`text-[16px] uppercase leading-[1.439] focus:outline-none font-bold  w-full md:w-auto py-3 bg-transparent
           `}
                onChange={e => setActiveOption(e.target.value)}
              >
                {downloads?.map((option, i) => (
                  <option className="text-black" key={i} value={i}>
                    Showing results from {option?.label}
                  </option>
                ))}
              </select>

              <div className="absolute top-[42%] right-[-1rem] ">
                <Icon type={IconType.Caret} />
              </div>
            </form>
          )}

          {!hideColumnNames && (
          <div
            className={`md:flex items-center space-x-[5px] font-medium md:space-x-[18px] w-full justify-end text-left text-14 hidden`}
          >
            {layout === "simple" ? (
              <div>Download</div>
            ) : (
              <>
                <div>RNS</div>
                <div>Slides</div>
                <div>Webcast</div>
              </>
            )}
          </div>
          )}
        </div>

        <div className="w-full h-px bg-brown bg-opacity-60"></div>

        {downloads && downloads[activeOption] && downloads[activeOption]?.download.map((doc, i) => (
          <div key={`doc${i}`}>
            <ListItem {...doc} layout={layout} />
          </div>
        ))}
      </div>
    </Section>
  )
}

export const ListItem = ({
  year,
  title,
  file,
  rns,
  webcast,
  webcastIsExternal,
  presentation,
  layout,
  download = false,
}) => {
  const [onHover, setOnHover] = useState(false)

  return (
    <>
      <div
        className={`flex justify-between pt-[33px] pb-[31px] items-center `}
        onMouseEnter={() => setOnHover(true)}
        onMouseLeave={() => setOnHover(false)}
      >
        <div>
          <div className="md:flex text-[18px] font-semibold leading-[1.439] cursor-default ">
            {year && (
              <>
                <div className="text-brown">{year}</div>
                <span className="hidden mx-1 md:block"> / </span>{" "}
              </>
            )}
            <div>{title}</div>
          </div>

          {/* <motion.div
            animate={{
              opacity: onHover ? 1 : 0,
              width: onHover ? "100%" : "0%",
            }}
            transition={{ duration: 0.4, ease: "easeInOut" }}
            className="h-px bg-black"
          ></motion.div> */}
        </div>

        <div
          className={`flex items-center download-list h-[32px] ${
            layout === "simple"
              ? "justify-end pr-3"
              : "justify-end  space-x-[5px] md:space-x-0  md:justify-evenly"
          }  w-full text-center max-w-[194px] `}
        >
          {layout === "simple" ? (
            <a
              className={`h-[32px] ${
                file
                  ? "opacity-100 cursor-pointer"
                  : "hidden md:block md:opacity-5 pointer-events-none"
              }`}
              download={download ? true : false}
              href={
                download ? file?.localFile?.publicURL : file?.localFile?.url
              }
              target="_blank"
            >
              <AnimatedDownload onHover={file && onHover} />
            </a>
          ) : (
            <>
              <a
                className={`  h-[32px]${
                  rns
                    ? "opacity-100 cursor-pointer"
                    : "hidden md:block md:opacity-5 pointer-events-none"
                }`}
                href={rns?.localFile?.url}
                target="_blank"
              >
                <AnimatedPDF />
              </a>
              <a
                className={`  h-[32px]${
                  presentation
                    ? "opacity-100 cursor-pointer"
                    : "hidden md:block md:opacity-5 pointer-events-none"
                }`}
                href={presentation?.localFile?.url}
                target="_blank"
              >
                <AnimatedPrestentation />
              </a>

              <div
                className={`h-[32px] ${
                  webcast
                    ? "opacity-100  cursor-pointer"
                    : "hidden md:block md:opacity-5 pointer-events-none"
                }`}
              >
                {webcastIsExternal ? (
                  <a href={webcast} target="_blank"><AnimatedWebcast /></a>
                ) : (
                <VideoModal
                  iconButton={true}
                  channel="custom"
                  customVideo={webcast}
                />
                )}
              </div>
            </>
          )}
        </div>
      </div>

      <div className="w-full h-px bg-brown"></div>
    </>
  )
}

export const PresentationIcon = () => (
  <svg
    width="33"
    height="32"
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      y="31.8242"
      width="31.8214"
      height="33"
      transform="rotate(-90 0 31.8242)"
      fill="#A71930"
    />
    <g clipPath="url(#clip0)">
      <path
        d="M21.5 11.5078H12.5V11.0078C12.5 10.8752 12.5527 10.748 12.6464 10.6543C12.7402 10.5605 12.8674 10.5078 13 10.5078H21C21.1326 10.5078 21.2598 10.5605 21.3536 10.6543C21.4473 10.748 21.5 10.8752 21.5 11.0078V11.5078Z"
        fill="white"
      />
      <path
        d="M22.5 12.5078H11.5C11.3674 12.5078 11.2402 12.5605 11.1464 12.6543C11.0527 12.748 11 12.8752 11 13.0078V21.0078C11 21.1404 11.0527 21.2676 11.1464 21.3614C11.2402 21.4551 11.3674 21.5078 11.5 21.5078H22.5C22.6326 21.5078 22.7598 21.4551 22.8536 21.3614C22.9473 21.2676 23 21.1404 23 21.0078V13.0078C23 12.8752 22.9473 12.748 22.8536 12.6543C22.7598 12.5605 22.6326 12.5078 22.5 12.5078ZM16 19.0078V15.0078L19 17.0078L16 19.0078Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect
          width="12"
          height="12"
          fill="white"
          transform="translate(11 10.0078)"
        />
      </clipPath>
    </defs>
  </svg>
)
export const RNSIcon = () => (
  <svg
    width="33"
    height="32"
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      y="31.8281"
      width="31.8214"
      height="33"
      transform="rotate(-90 0 31.8281)"
      fill="#A71930"
    />
    <g clipPath="url(#clip0)">
      <path
        d="M11.125 20.8828V21.6328C11.125 21.8578 11.275 22.0078 11.5 22.0078H20.5C20.725 22.0078 20.875 21.8578 20.875 21.6328V20.8828H11.125Z"
        fill="white"
      />
      <path d="M18.25 10.6055V12.6305H20.275L18.25 10.6055Z" fill="white" />
      <path
        d="M14.0875 16.9453H13.9375V17.4328H14.05C14.125 17.4328 14.2 17.3953 14.275 17.3578C14.35 17.3203 14.35 17.2453 14.35 17.1703C14.35 17.0203 14.275 16.9453 14.0875 16.9453Z"
        fill="white"
      />
      <path
        d="M16.0001 16.9453H15.8501V18.1453H15.9626C16.1501 18.1453 16.2626 18.1078 16.3376 17.9953C16.4126 17.8828 16.4501 17.7328 16.4501 17.5453C16.4501 17.3578 16.4126 17.2078 16.3376 17.0953C16.2626 16.9828 16.1876 16.9453 16.0001 16.9453Z"
        fill="white"
      />
      <path
        d="M21.625 14.8828H20.875V13.3828H17.875C17.65 13.3828 17.5 13.2328 17.5 13.0078V10.0078H11.5C11.275 10.0078 11.125 10.1578 11.125 10.3828V14.8828H10.375C10.15 14.8828 10 15.0328 10 15.2578V19.7578C10 19.9828 10.15 20.1328 10.375 20.1328H21.625C21.85 20.1328 22 19.9828 22 19.7578V15.2578C22 15.0328 21.85 14.8828 21.625 14.8828ZM14.725 17.7328C14.575 17.8453 14.3875 17.9203 14.125 17.9203H13.975V18.6328H13.375V16.4953H14.125C14.3875 16.4953 14.6125 16.5703 14.725 16.6828C14.8375 16.7953 14.9125 16.9828 14.9125 17.1703C14.9125 17.3953 14.875 17.5828 14.725 17.7328ZM16.7875 18.3328C16.6 18.5203 16.3 18.6328 15.9625 18.6328H15.2875V16.4953H16.0375C16.375 16.4953 16.6375 16.5703 16.825 16.7578C17.0125 16.9453 17.0875 17.1703 17.0875 17.5078C17.0875 17.8828 16.975 18.1453 16.7875 18.3328ZM18.7375 16.9453H18.025V17.3578H18.6625V17.8078H18.025V18.6328H17.4625V16.4953H18.7375V16.9453Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect
          width="12"
          height="12"
          fill="white"
          transform="translate(10 10.0078)"
        />
      </clipPath>
    </defs>
  </svg>
)

export const layoutQueryFragment = graphql`
  fragment DownloadList on WpPage_Page_FlexibleContent {
    ... on WpPage_Page_FlexibleContent_DownloadList {
      fieldGroupName
      layout
      ...coreCopyStackDownloadList
      ...sectionDownloadList
      hideColumnNames
      downloads {
        label
        download {
          year
          title
          file {
            localFile {
              publicURL
              url
            }
          }
          rns {
            localFile {
              publicURL
              url
            }
          }
          presentation {
            localFile {
              publicURL
              url
            }
          }
          webcast
          webcastIsExternal
        }
      }
    }
  }
`
