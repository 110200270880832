import React, { useState } from "react"
import { motion } from "framer-motion"

const AnimatedPrestentation = ({ ...other }) => {
  const [onHover, setOnHover] = useState(false)

  return (
    <div
      {...other}
      className=" bg-red"
      onMouseEnter={() => setOnHover(true)}
      onMouseLeave={() => setOnHover(false)}
    >
      <div className="p-[10px] relative flex flex-col items-center justify-center">
        <motion.div
          initial={{ scale: 1, y: 0 }}
          animate={{ scale: onHover ? 1.3 : 1, y: onHover ? "-2px" : 0 }}
          transition={{
            duration: 0.2,
            delay: 0.2,
            scale: { duration: 0.3 },
          }}
        >
          <svg
            width="10"
            height="2"
            viewBox="0 0 10 2"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.5 1.50781H0.5V1.00781C0.5 0.875212 0.5527 0.748012 0.6464 0.654312C0.7402 0.560512 0.8674 0.507812 1 0.507812H9C9.1326 0.507812 9.2598 0.560512 9.3536 0.654312C9.4473 0.748012 9.5 0.875212 9.5 1.00781V1.50781Z"
              fill="white"
            />
          </svg>
        </motion.div>
        <motion.div
          initial={{ scale: 1 }}
          animate={{ scale: onHover ? 1.3 : 1 }}
          transition={{
            duration: 0.2,
            delay: 0.3,
            scale: { duration: 0.3 },
          }}
        >
          <svg
            width="12"
            height="10"
            viewBox="0 0 12 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.5 0.500001H0.5C0.3674 0.500001 0.2402 0.552701 0.1464 0.646501C0.0527005 0.740201 0 0.867401 0 1V9C0 9.1326 0.0527005 9.2598 0.1464 9.3536C0.2402 9.4473 0.3674 9.5 0.5 9.5H11.5C11.6326 9.5 11.7598 9.4473 11.8536 9.3536C11.9473 9.2598 12 9.1326 12 9V1C12 0.867401 11.9473 0.740201 11.8536 0.646501C11.7598 0.552701 11.6326 0.500001 11.5 0.500001Z"
              fill="white"
            />
          </svg>
        </motion.div>
        <motion.div
          className="absolute inset-0 flex items-center justify-center"
          animate={{ scale: onHover ? 1.8 : 1, y: onHover ? "-1px" : 0 }}
          transition={{
            duration: 0.3,
            delay: 0.3,
            scale: { duration: 0.3 },
            y: { delay: 0.1 },
          }}
        >
          <svg
            className="mt-[1px] ml-[1px]"
            width="3"
            height="4"
            viewBox="0 0 3 4"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M0 4V0L3 2L0 4Z" fill="#A71930" />
          </svg>
        </motion.div>{" "}
      </div>
    </div>
  )
}

export default AnimatedPrestentation
