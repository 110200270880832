import m4 from "../js/m4.js"
class ImageStarfield {
  // How far back should images be respawned from
  imageZPushback = 2000
  // Starting zValue of camera
  cameraZValue = 500
  // Z value change per frame for camera
  cameraZValueMod = -3.5
  // Camera offset values for controlling with mouse
  cameraXOffset = 0
  cameraXDestination = 0
  cameraYOffset = 0
  cameraYDestination = 0
  // Camera field of view
  cameraFov = 50

  constructor($wrapper, images) {
    this.$wrapper = $wrapper
    this.images = images

    // Ensure canvas exists
    if (!$wrapper) {
      console.log("Starfield: canvas not found")
      return
    }

    // Setup webgl context
    this.gl = $wrapper.getContext("webgl")
    if (!this.gl) {
      console.log("Starfield: Webgl not available")
      return
    }

    // Create program and shaders
    this.program = this.createProgram()
    if (!this.program) {
      console.log("Starfield: Failed to create program")
      return
    }

    // Setup attribute locations and create textures
    this.setupAttributes()
    this.textures = this.createTextures()

    // Bind mouse movement
    this.mouseAreaSize = this.$wrapper.getBoundingClientRect()
    this.mouseCenter = {
      x: this.gl.canvas.clientWidth / 2,
      y: this.gl.canvas.clientHeight / 2,
    }
    document.onmousemove = this.onMouseMove.bind(this)

    // Draw
    this.draw()
  }

  vertexShader = `attribute vec4 a_position;
    attribute vec2 a_texcoord;
    uniform mat4 u_matrix;
    varying vec2 v_texcoord;
    
    void main() {
      gl_Position = u_matrix * a_position;
      v_texcoord = a_texcoord;
    }`

  fragShader = `precision mediump float;
    varying vec2 v_texcoord;
    uniform float u_fade;
    uniform sampler2D u_texture;
    
    void main() {
       vec4 color = texture2D(u_texture, v_texcoord);
       gl_FragColor = mix(color, vec4(0,0,0,0), u_fade); 
    }`

  createProgram() {
    // Boilerplate webgl program / shader setup

    const { gl } = this

    const vertexShader = this.createShader(
      gl,
      gl.VERTEX_SHADER,
      this.vertexShader
    )
    const fragmentShader = this.createShader(
      gl,
      gl.FRAGMENT_SHADER,
      this.fragShader
    )

    const program = gl.createProgram()
    gl.attachShader(program, vertexShader)
    gl.attachShader(program, fragmentShader)
    gl.linkProgram(program)
    const success = gl.getProgramParameter(program, gl.LINK_STATUS)
    if (success) {
      return program
    } else {
      gl.deleteProgram(program)
      return false
    }
  }

  createShader(gl, type, source) {
    // Boilerplate shader setup

    const shader = gl.createShader(type)
    gl.shaderSource(shader, source)
    gl.compileShader(shader)
    const success = gl.getShaderParameter(shader, gl.COMPILE_STATUS)
    if (success) {
      return shader
    }

    gl.deleteShader(shader)
  }

  setupAttributes() {
    const { program, gl } = this

    // Get attribute and uniform locations
    this.positionLocation = gl.getAttribLocation(program, "a_position")
    this.texcoordLocation = gl.getAttribLocation(program, "a_texcoord")
    this.fadeUniformLocation = gl.getUniformLocation(program, "u_fade")
    this.matrixLocation = gl.getUniformLocation(program, "u_matrix")

    // Create position buffer for verticies
    this.positionBuffer = gl.createBuffer()
    gl.bindBuffer(gl.ARRAY_BUFFER, this.positionBuffer)

    // Set texture coordination buffer
    this.texcoordBuffer = gl.createBuffer()
    gl.bindBuffer(gl.ARRAY_BUFFER, this.texcoordBuffer)
    gl.vertexAttribPointer(this.texcoordLocation, 2, gl.FLOAT, false, 0, 0)

    // Texture coordinates
    gl.bufferData(
      gl.ARRAY_BUFFER,
      new Float32Array([0, 0, 0, 1, 1, 0, 0, 1, 1, 1, 1, 0]),
      gl.STATIC_DRAW
    )
  }

  createTextures() {
    const { gl } = this
    // We have to check if image dimensions are power of 2 because of a webgl oddity
    function isPowerOf2(value) {
      return (value & (value - 1)) === 0
    }
    // We go through all the images and return a webgl texture
    return this.images.map(imageSettings => {
      const texture = gl.createTexture()
      gl.bindTexture(gl.TEXTURE_2D, texture)

      // Fill the texture with a color to show before image is loaded
      gl.texImage2D(
        gl.TEXTURE_2D,
        0,
        gl.RGBA,
        1,
        1,
        0,
        gl.RGBA,
        gl.UNSIGNED_BYTE,
        new Uint8Array([0, 0, 0, 255])
      )

      // Load image
      const image = new Image()
      image.src = imageSettings.url
      image.addEventListener("load", function () {
        gl.bindTexture(gl.TEXTURE_2D, texture)
        gl.texImage2D(
          gl.TEXTURE_2D,
          0,
          gl.RGBA,
          gl.RGBA,
          gl.UNSIGNED_BYTE,
          image
        )
        // Check if is power of 2
        if (isPowerOf2(image.width) && isPowerOf2(image.height)) {
          gl.generateMipmap(gl.TEXTURE_2D)
        } else {
          gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE)
          gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE)
          gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR)
        }
      })

      return texture
    })
  }

  setRectToBuffer(gl, image) {
    const x = image.position.x
    const y = image.position.y
    const x2 = x + image.width
    const y2 = y + image.height
    const z = image.position.z

    const shape = [x, y, z, x, y2, z, x2, y, z, x, y2, z, x2, y2, z, x2, y, z]
    var positions = new Float32Array(shape)

    var matrix = m4.xRotation(Math.PI)
    matrix = m4.translate(matrix, -50, -75, -15)

    for (var ii = 0; ii < positions.length; ii += 3) {
      var vector = m4.vectorMultiply(
        [positions[ii + 0], positions[ii + 1], positions[ii + 2], 1],
        matrix
      )
      positions[ii + 0] = vector[0]
      positions[ii + 1] = vector[1]
      positions[ii + 2] = vector[2]
    }

    gl.bufferData(gl.ARRAY_BUFFER, positions, gl.STATIC_DRAW)
  }

  draw() {
    this.updateCamera()
    this.drawSetup()
    this.drawImages()
    window.requestAnimationFrame(this.draw.bind(this))
  }

  updateCamera() {
    // Camera movement on mouse changes

    const cameraMaxDist = 100
    const cameraSpeed = 1.25

    const maxDistanceClamp = val => {
      if (val > cameraMaxDist) {
        return cameraMaxDist
      }
      if (val < -cameraMaxDist) {
        return -cameraMaxDist
      }
      return val
    }

    // Set x and y camera distances

    {
      const xDiff = this.cameraXOffset - this.cameraXDestination
      let distance = Math.abs(xDiff) / 300
      if (distance > 1) distance = 1
      this.cameraXOffset +=
        xDiff > 0 ? distance * cameraSpeed : -distance * cameraSpeed
      this.cameraXOffset = maxDistanceClamp(this.cameraXOffset)
    }

    {
      const yDiff = this.cameraYOffset - this.cameraYDestination
      let distance = Math.abs(yDiff) / 300
      if (distance > 1) distance = 1
      this.cameraYOffset +=
        yDiff > 0 ? -distance * cameraSpeed : distance * cameraSpeed
      this.cameraYOffset = maxDistanceClamp(this.cameraYOffset)
    }

    this.cameraZValue += this.cameraZValueMod
  }

  drawSetup() {
    const { gl, program } = this

    // Boilerplate

    this.resizeCanvasToDisplaySize(gl.canvas, 1)
    gl.viewport(0, 0, gl.canvas.width, gl.canvas.height)
    gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT)
    gl.enable(gl.CULL_FACE)
    gl.enable(gl.DEPTH_TEST)
    gl.useProgram(program)
    gl.enableVertexAttribArray(this.positionLocation)
    gl.bindBuffer(gl.ARRAY_BUFFER, this.positionBuffer)

    // Attribs
    gl.vertexAttribPointer(this.positionLocation, 3, gl.FLOAT, true, 0, 0)
    gl.enableVertexAttribArray(this.texcoordLocation)
    gl.bindBuffer(gl.ARRAY_BUFFER, this.texcoordBuffer)
    gl.vertexAttribPointer(this.texcoordLocation, 2, gl.FLOAT, false, 0, 0)

    // Camera projection
    const aspect = gl.canvas.clientWidth / gl.canvas.clientHeight
    const zNear = 1
    const zFar = 3000
    const fieldOfView = (this.cameraFov * Math.PI) / 180
    const projectionMatrix = m4.perspective(fieldOfView, aspect, zNear, zFar)
    let cameraMatrix = m4.yRotation(0)
    cameraMatrix = m4.translate(
      cameraMatrix,
      0 + this.cameraXOffset,
      150 + this.cameraYOffset,
      this.cameraZValue
    )

    // Make a view matrix from the camera matrix
    const viewMatrix = m4.inverse(cameraMatrix)

    // Compute a view projection matrix
    const viewProjectionMatrix = m4.multiply(projectionMatrix, viewMatrix)
    gl.uniformMatrix4fv(this.matrixLocation, false, viewProjectionMatrix)
  }

  drawImages() {
    const { gl } = this

    const primitiveType = gl.TRIANGLES
    const offset = 0
    const count = 6
    const zValue = this.cameraZValue
    const zPushback = this.imageZPushback

    this.images.forEach((image, i) => {
      if (!image.startPosition) {
        image.startPosition = { ...image.position }
      }

      if (image.positionMod) {
        Object.keys(image.positionMod).forEach(key => {
          image.position[key] += image.positionMod[key]
        })
      }

      if (image.position.z + zValue < 0) {
        image.position.z = Math.abs(zValue) + zPushback
        image.position.x = image.startPosition.x
        image.position.y = image.startPosition.y
      }

      let fade = (image.position.z + zValue) / zPushback + 0.3

      if(Math.abs(image.position.z + zValue) < 300){
        fade = 1.5 - ((1 / 300) * (image.position.z + zValue));
      }

      gl.uniform1f(this.fadeUniformLocation, fade)

      gl.bindTexture(gl.TEXTURE_2D, this.textures[i])

      gl.bindBuffer(gl.ARRAY_BUFFER, this.positionBuffer)
      this.setRectToBuffer(gl, image)

      gl.drawArrays(primitiveType, offset, count)
    })
  }

  resizeCanvasToDisplaySize(canvas, multiplier) {
    const width = (canvas.clientWidth * multiplier) | 0
    const height = (canvas.clientHeight * multiplier) | 0
    if (canvas.width !== width || canvas.height !== height) {
      canvas.width = width
      canvas.height = height
      return true
    }
    return false
  }

  onMouseMove(e) {
    const offsetX = e.clientX - this.mouseAreaSize.left
    const offsetY = e.clientY - this.mouseAreaSize.top
    this.cameraXDestination = offsetX - this.mouseCenter.x
    this.cameraYDestination = offsetY - this.mouseCenter.y
  }
}

export default ImageStarfield
