import React, { Fragment } from "react"
import useSpanHeading from "~/hooks/useSpanHeading"

// Returns a child node at a specific index or null

export const getReactChildAtIndex = (
  children: React.ReactNode,
  searchIndex: number
) =>
  React.Children.map(children, (child, i) => (i === searchIndex ? child : null))

// Left or right enum

export enum LeftOrRight {
  Left = "left",
  Right = "right",
}

// Max width code conversion

export const TextCodeConverter = ({ children }) => {
  return React.Children.map(children, child => {
    if (typeof child === "string" && child) {
      return applyTextCodeClassesToString(child)
    } else {
      return child
    }
  })
}

export const applyTextCodeClassesToString = string => {
  const matches = string.split("|| ")
  if (matches.length === 3) {
    const string = matches[2]
    const attributeValuePairs = matches[1]
      .split(" ")
      .map(pair => pair.split(":"))
      .filter(pair => pair.length === 2)
    if (attributeValuePairs.length === 0) return string
    let classes = []
    attributeValuePairs.forEach(pair => {
      const [name, value] = pair
      if (name === "mw") {
        if (value === "xsmall") {
          classes.push("max-w-[100px]")
        } else if (value === "small") {
          classes.push("md:max-w-[350px]")
        } else if (value === "mediumSmallXL") {
          classes.push("md:max-w-[400px]")
        } else if (value === "mediumSmall") {
          classes.push("md:max-w-[450px]")
        } else if (value === "medium") {
          classes.push("max-w-[500px]")
        } else if (value === "mediumLarge") {
          classes.push("max-w-[543px]")
        } else if (value === "mediumXLarge") {
          classes.push("max-w-[616px]")
        } else if (value === "mediumXXLarge") {
          classes.push("max-w-[684px]")
        } else if (value === "largeSmall") {
          classes.push("max-w-[740px]")
        } else if (value === "large") {
          classes.push("max-w-[789px]")
        } else if (value === "xlarge") {
          classes.push("max-w-[900px]")
        } else if (value === "xxlarge") {
          classes.push("max-w-[950px]")
        } else if (value === "xxxlarge") {
          classes.push("max-w-[1008px] ")
        }
      }
      if (name === "fs") {
        if (value === "30px") {
          classes.push("text-[30px] leading-[1.214]")
        } else if (value === "38px") {
          classes.push("text-[38px]")
        } else if (value === "55px") {
          classes.push("text-[55px]")
        } else if (value === "70px") {
          classes.push("xl:text-[70px] xl:leading-[1.10] xl:tracking-[0.03em]")
        }
      }
      if (name === "case") {
        if (value === "upper") {
          classes.push("uppercase")
        } else if (value === "normal") {
          classes.push("normal-case")
        }
      }
      if (name === "fw") {
        if (value === "normal") {
          classes.push("font-normal")
        } else if (value === "bold") {
          classes.push("font-bold")
        } else if (value === "semibold") {
          classes.push("font-semibold")
        }
      }
      if (name === "mb") {
        if (value === "large") {
          classes.push("md:mb-[123px]")
        }
      }
      if (name === "span") {
        if (value === "circle-tail") {
          classes.push("circle-loop-tail")
        }
      }
    })
    return (
      <div
        dangerouslySetInnerHTML={{ __html: useSpanHeading(string) }}
        className={classes.join(" ")}
      />
    )
  } else {
    if (string.match(/\|(.+)\|/)) {
      return (
        <span dangerouslySetInnerHTML={{ __html: useSpanHeading(string) }} />
      )
    } else {
      return string
    }
  }
}
