import React, { Fragment } from "react"
import { graphql } from "gatsby"
import { Links } from "~/components/FlexibleLayouts/elements/Link"
import { TextCodeConverter } from "~/components/FlexibleLayouts/elements/helpers"

// Subheading

export interface SubheadingProps {
  children: React.ReactNode
  Type: string
}

export const Subheading = ({ children, Type = "h6" }: SubheadingProps) => (
  <Type
    className={`font-semibold text-brown text-[14px] md:text-[17px] leading-tight`}
  >
    <TextCodeConverter>{children}</TextCodeConverter>
  </Type>
)

// Secondary subheading

export interface SecondarySubheadingProps {
  children: React.ReactNode
  Type: string
}

export const SecondarySubheading = ({
  children,
  Type = "h6",
}: SecondarySubheadingProps) => (
  <Type className={`font-bold text-brown text-[20px] leading-[27px]`}>
    <TextCodeConverter>{children}</TextCodeConverter>
  </Type>
)

// Tertiary subheading

export interface TertiarySubheadingProps {
  children: React.ReactNode
  Type: string
}

export const TertiarySubheading = ({
  children,
  Type = "h6",
}: TertiarySubheadingProps) => (
  <Type className={`font-extrabold uppercase text-[16px] leading-[25px]`}>
    <TextCodeConverter>{children}</TextCodeConverter>
  </Type>
)

export interface XLSubheadingProps {
  children: React.ReactNode
  Type: string
}

export const XLSubheading = ({ children, Type = "h6" }: XLSubheadingProps) => (
  <Type className={`font-bold text-brown uppercase text-[30px] leading-[27px]`}>
    <TextCodeConverter>{children}</TextCodeConverter>
  </Type>
)

// Primary heading

export enum HeadingSizeOption {
  Default,
  XSmall,
  Small,
  Medium,
  Large,
  XLarge,
}

export interface HeadingProps {
  children: React.ReactNode
  size: HeadingSizeOption
  Type: string
  className: string
}

export const Heading = ({
  children,
  size,
  Type = "h3",
  className = "",
}: HeadingProps) => {
  let sizeClasses =
    "text-[24px] leading-[24px] md:text-[38px] md:leading-[38px]"
  if (size === HeadingSizeOption.XSmall) {
    sizeClasses = "text-[16px] leading-[23px]"
  }
  if (size === HeadingSizeOption.Small) {
    sizeClasses = "text-[20px] leading-[25px] md:text-[24px] md:leading-[31px]"
  }
  if (size === HeadingSizeOption.Medium) {
    sizeClasses = "text-[20px] leading-[25px] md:text-[45px] md:leading-[50px]"
  }
  if (size === HeadingSizeOption.Large) {
    sizeClasses =
      "text-[32px] leading-[32px] md:text-[48px] md:leading-[48px] xl:text-[58px] xl:leading-[56px]"
  }
  if (size === HeadingSizeOption.XLarge) {
    sizeClasses =
      "text-[32px] leading-[32px] md:text-[48px] md:leading-[48px] xl:text-[70px] xl:leading-[77px]"
  }
  return (
    <Type className={`font-extrabold uppercase ${sizeClasses} ${className}`}>
      <TextCodeConverter>{children}</TextCodeConverter>
    </Type>
  )
}

// Secondary heading

export interface SecondaryHeadingProps {
  children: React.ReactNode
  Type: string
}

export const SecondaryHeading = ({
  children,
  Type = "h4",
}: SecondaryHeadingProps) => (
  <Type className={`font-bold text-slate text-[24px] leading-[31px]`}>
    <TextCodeConverter>{children}</TextCodeConverter>
  </Type>
)

// Body copy

export enum BodyCopySizeOption {
  Default,
  Small,
  Medium,
  MediumWide,
  Large,
}

export interface BodyCopyProps {
  children: React.ReactNode
  size: BodyCopySizeOption
}

export const BodyCopy = ({ children, size }: BodyCopyProps) => {
  let sizeClasses =
    "text-[15px] leading-[25px] md:text-[17px] md:leading-[30px]"
  if (size === BodyCopySizeOption.Small) {
    sizeClasses = "text-[15px] leading-[25px] md:text-[16px] md:leading-[26px]"
  }
  if (size === BodyCopySizeOption.Medium) {
    sizeClasses = "text-[15px] leading-[25px] md:text-[20px] md:leading-[1.439]"
  }
  if (size === BodyCopySizeOption.MediumWide) {
    sizeClasses = "text-[15px] leading-[25px] md:text-[21px] md:leading-[1.604]"
  }
  if (size === BodyCopySizeOption.Large) {
    sizeClasses = "text-[15px] leading-[25px] xl:text-[24px] xl:leading-[34px]"
  }
  return (
    <div className={`font-medium ${sizeClasses}`}>
      <TextCodeConverter>{children}</TextCodeConverter>
    </div>
  )
}

// Type Combinations

export interface CoreCopyStackProps {
  subheading: string
  subheadingType: string
  heading: string
  headingType: string
  text: string
  links: LinkProps[]
  bodySize: BodyCopySizeOption
  headingSize: HeadingSizeOption
  headingClass: string
  linkClass: string
}

export const CoreCopyStack = ({
  subheading,
  subheadingType = "h4",
  heading,
  headingType = "h3",
  text,
  links,
  bodySize,
  headingSize,
  headingClass,
  linkClass,
}: CoreCopyStackProps) => (
  <Fragment>
    {subheading && <Subheading Type={subheadingType}>{subheading}</Subheading>}
    {heading && (
      <div
        className={`${subheading ? "mt-3 md:mt-4" : ""}${
          text ? " mb-3 md:mb-4" : ""
        }`}
      >
        <Heading size={headingSize} Type={headingType} className={headingClass}>
          {heading}
        </Heading>
      </div>
    )}
    {text && <BodyCopy size={bodySize}>{text}</BodyCopy>}
    {links && (
      <div className="pt-[12px]">
        <Links links={links} className={linkClass} />
      </div>
    )}
  </Fragment>
)

export const CoreCopyStackFragment = graphql`
  fragment coreCopyStackTwoColumnList on WpPage_Page_FlexibleContent_TwoColumnList {
    coreCopyStack {
      subheading
      heading
      text
      links {
        videoEmbed
        email
        behaviour
        file {
          localFile {
            publicURL
          }
        }
        link {
          title
          url
        }
        type
        icon
      }
    }
  }
  fragment coreCopyStackDefaultHero on WpPage_Page_FlexibleContent_DefaultHero {
    coreCopyStack {
      subheading
      heading
      text
      links {
        videoEmbed
        email
        behaviour
        file {
          localFile {
            publicURL
          }
        }
        link {
          title
          url
        }
        type
        icon
      }
    }
  }
  fragment coreCopyStackLegalHero on WpPage_Page_FlexibleContent_LegalHero {
    coreCopyStack {
      subheading
      heading
      text
      links {
        videoEmbed
        email
        behaviour
        file {
          localFile {
            publicURL
          }
        }
        link {
          title
          url
        }
        type
        icon
      }
    }
  }
  fragment coreCopyStackEventsSection on WpPage_Page_FlexibleContent_EventsSection {
    coreCopyStack {
      subheading
      heading
      text
      links {
        videoEmbed
        email
        behaviour
        file {
          localFile {
            publicURL
          }
        }
        link {
          title
          url
        }
        type
        icon
      }
    }
  }
  fragment coreCopyStackFullWidthImage on WpPage_Page_FlexibleContent_FullWidthImage {
    coreCopyStack {
      subheading
      heading
      text
      links {
        videoEmbed
        email
        behaviour
        file {
          localFile {
            publicURL
          }
        }
        link {
          title
          url
        }
        type
        icon
      }
    }
  }
  fragment coreCopyStackCardSlider on WpPage_Page_FlexibleContent_CardSlider {
    coreCopyStack {
      subheading
      heading
      text
      links {
        videoEmbed
        email
        behaviour
        file {
          localFile {
            publicURL
          }
        }
        link {
          title
          url
        }
        type
        icon
      }
    }
  }
  fragment coreCopyStackDownloadGrid on WpPage_Page_FlexibleContent_DownloadGrid {
    coreCopyStack {
      subheading
      heading
      text
      links {
        videoEmbed
        email
        behaviour
        file {
          localFile {
            publicURL
          }
        }
        link {
          title
          url
        }
        type
        icon
      }
    }
  }
  fragment coreCopyStackDownloadList on WpPage_Page_FlexibleContent_DownloadList {
    coreCopyStack {
      subheading
      heading
      text
      links {
        videoEmbed
        email
        behaviour
        file {
          localFile {
            publicURL
          }
        }
        link {
          title
          url
        }
        type
        icon
      }
    }
  }
  fragment coreCopyStackCareersHero on WpPage_Page_FlexibleContent_CareersHero {
    coreCopyStack {
      subheading
      heading
      text
      links {
        videoEmbed
        email
        behaviour
        file {
          localFile {
            publicURL
          }
        }
        link {
          title
          url
        }
        type
        icon
      }
    }
  }
  fragment coreCopyStackFeaturedIconCards on WpPage_Page_FlexibleContent_FeaturedIconCards {
    coreCopyStack {
      subheading
      heading
      text
      links {
        videoEmbed
        email
        behaviour
        file {
          localFile {
            publicURL
          }
        }
        link {
          title
          url
        }
        type
        icon
      }
    }
  }
  fragment coreCopyStackHubCta on WpPage_Page_FlexibleContent_HubCta {
    coreCopyStack {
      subheading
      heading
      text
      links {
        videoEmbed
        email
        behaviour
        file {
          localFile {
            publicURL
          }
        }
        link {
          title
          url
        }
        type
        icon
      }
    }
  }
  fragment coreCopyStackTabbedSection on WpPage_Page_FlexibleContent_TabbedSection {
    coreCopyStack {
      subheading
      heading
      text
      links {
        videoEmbed
        email
        behaviour
        file {
          localFile {
            publicURL
          }
        }
        link {
          title
          url
        }
        type
        icon
      }
    }
  }
  fragment coreCopyStackAnchorNav on WpPage_Page_FlexibleContent_AnchorNav {
    coreCopyStack {
      subheading
      heading
      text
      links {
        videoEmbed
        email
        behaviour
        file {
          localFile {
            publicURL
          }
        }
        link {
          title
          url
        }
        type
        icon
      }
    }
  }
  fragment coreCopyStackSustainability on WpPage_Page_FlexibleContent_Sustainability {
    coreCopyStack {
      subheading
      heading
      text
      links {
        videoEmbed
        email
        behaviour
        file {
          localFile {
            publicURL
          }
        }
        link {
          title
          url
        }
        type
        icon
      }
    }
  }
  fragment coreCopyStackNumberedCards on WpPage_Page_FlexibleContent_NumberedCards {
    coreCopyStack {
      subheading
      heading
      text
      links {
        videoEmbed
        email
        behaviour
        file {
          localFile {
            publicURL
          }
        }
        link {
          title
          url
        }
        type
        icon
      }
    }
  }
  fragment coreCopyStackStaggeredCards on WpPage_Page_FlexibleContent_StaggeredCards {
    coreCopyStack {
      subheading
      heading
      text
      links {
        videoEmbed
        email
        behaviour
        file {
          localFile {
            publicURL
          }
        }
        link {
          title
          url
        }
        type
        icon
      }
    }
  }
  fragment copyStackStaggeredCardsCards on WpPage_Page_FlexibleContent_StaggeredCards_staggeredCards {
    coreCopyStack {
      subheading
      heading
      text
      links {
        videoEmbed
        email
        behaviour
        file {
          localFile {
            publicURL
          }
        }
        link {
          title
          url
        }
        type
        icon
      }
    }
  }
  fragment coreCopyStackTwoColumnGridList on WpPage_Page_FlexibleContent_TwoColumnGridList {
    coreCopyStack {
      subheading
      heading
      text
      links {
        videoEmbed
        email
        behaviour
        file {
          localFile {
            publicURL
          }
        }
        link {
          title
          url
        }
        type
        icon
      }
    }
  }
  fragment coreCopyStackTrackRecord on WpPage_Page_FlexibleContent_TrackRecord {
    coreCopyStack {
      subheading
      heading
      text
      links {
        videoEmbed
        email
        behaviour
        file {
          localFile {
            publicURL
          }
        }
        link {
          title
          url
        }
        type
        icon
      }
    }
  }
  fragment coreCopyStackTextAndMediaColumns on WpPage_Page_FlexibleContent_TextAndMediaColumns {
    coreCopyStack {
      subheading
      heading
      text
      links {
        videoEmbed
        email
        behaviour
        file {
          localFile {
            publicURL
          }
        }
        link {
          title
          url
        }
        type
        icon
      }
    }
  }
  fragment coreCopyStackTextAndMediaColumnsContained on WpPage_Page_FlexibleContent_TextAndMediaColumnsContained {
    coreCopyStack {
      subheading
      heading
      text
      links {
        videoEmbed
        email
        behaviour
        file {
          localFile {
            publicURL
          }
        }
        link {
          title
          url
        }
        type
        icon
      }
    }
  }
  fragment coreCopyStackTwoColumnStatsGrid on WpPage_Page_FlexibleContent_TwoColumnStatsGrid {
    coreCopyStack {
      subheading
      heading
      text
      links {
        videoEmbed
        email
        behaviour
        file {
          localFile {
            publicURL
          }
        }
        link {
          title
          url
        }
        type
        icon
      }
    }
  }
  fragment coreCopyStackMidpageHero on WpPage_Page_FlexibleContent_MidpageHero {
    coreCopyStack {
      subheading
      heading
      text
      links {
        videoEmbed
        email
        behaviour
        file {
          localFile {
            publicURL
          }
        }
        link {
          title
          url
        }
        type
        icon
      }
    }
  }
  fragment coreCopyStackHighlights on WpPage_Page_FlexibleContent_Highlights {
    coreCopyStack {
      subheading
      heading
      text
      links {
        videoEmbed
        email
        behaviour
        file {
          localFile {
            publicURL
          }
        }
        link {
          title
          url
        }
        type
        icon
      }
    }
  }
  fragment coreCopyStackLogoGrid on WpPage_Page_FlexibleContent_LogoGrid {
    coreCopyStack {
      subheading
      heading
      text
      links {
        videoEmbed
        email
        behaviour
        file {
          localFile {
            publicURL
          }
        }
        link {
          title
          url
        }
        type
        icon
      }
    }
  }
  fragment coreCopyStackSliderHistorySlider on WpPage_Page_FlexibleContent_SliderHistorySlider {
    coreCopyStack {
      subheading
      heading
      text
      links {
        videoEmbed
        email
        behaviour
        file {
          localFile {
            publicURL
          }
        }
        link {
          title
          url
        }
        type
        icon
      }
    }
  }
  fragment coreCopyStackPeopleSlider on WpPage_Page_FlexibleContent_PeopleSlider {
    coreCopyStack {
      subheading
      heading
      text
      links {
        videoEmbed
        email
        behaviour
        file {
          localFile {
            publicURL
          }
        }
        link {
          title
          url
        }
        type
        icon
      }
    }
  }
  fragment coreCopyStackTeamMembers on WpPage_Page_FlexibleContent_TeamMembers {
    coreCopyStack {
      subheading
      heading
      text
      links {
        videoEmbed
        email
        behaviour
        file {
          localFile {
            publicURL
          }
        }
        link {
          title
          url
        }
        type
        icon
      }
    }
  }
  fragment coreCopyStackNewsPosts on WpPage_Page_FlexibleContent_NewsPosts {
    coreCopyStack {
      subheading
      heading
      text
      links {
        videoEmbed
        email
        behaviour
        file {
          localFile {
            publicURL
          }
        }
        link {
          title
          url
        }
        type
        icon
      }
    }
  }
  fragment coreCopyStackOffices on WpPage_Page_FlexibleContent_Offices {
    coreCopyStack {
      subheading
      heading
      text
      links {
        videoEmbed
        email
        behaviour
        file {
          localFile {
            publicURL
          }
        }
        link {
          title
          url
        }
        type
        icon
      }
    }
  }
  fragment coreCopyStackListSwitcher on WpPage_Page_FlexibleContent_ListSwitcher {
    coreCopyStack {
      subheading
      heading
      text
      links {
        videoEmbed
        email
        behaviour
        file {
          localFile {
            publicURL
          }
        }
        link {
          title
          url
        }
        type
        icon
      }
    }
  }
  fragment coreCopyStackVennDiagram on WpPage_Page_FlexibleContent_VennDiagram {
    coreCopyStack {
      subheading
      heading
      text
      links {
        videoEmbed
        email
        behaviour
        file {
          localFile {
            publicURL
          }
        }
        link {
          title
          url
        }
        type
        icon
      }
    }
  }
  fragment coreCopyStackStaggeredTypographyCards on WpPage_Page_FlexibleContent_StaggeredTypographyCards {
    coreCopyStack {
      subheading
      heading
      text
      links {
        videoEmbed
        email
        behaviour
        file {
          localFile {
            publicURL
          }
        }
        link {
          title
          url
        }
        type
        icon
      }
    }
  }
  fragment coreCopyStackCardSliderSliders on WpPage_Page_FlexibleContent_CardSlider_sliders {
    coreCopyStack {
      subheading
      heading
      text
      links {
        videoEmbed
        email
        behaviour
        file {
          localFile {
            publicURL
          }
        }
        link {
          title
          url
        }
        type
        icon
      }
    }
  }
  fragment coreCopyStackStaggeredTypographyCardsStaggeredCards on WpPage_Page_FlexibleContent_StaggeredTypographyCards_staggeredCards {
    coreCopyStack {
      subheading
      heading
      text
      links {
        videoEmbed
        email
        behaviour
        file {
          localFile {
            publicURL
          }
        }
        link {
          title
          url
        }
        type
        icon
      }
    }
  }
  fragment coreCopyStackFeaturedNews on WpPage_Page_FlexibleContent_FeaturedNews {
    coreCopyStack {
      subheading
      heading
      text
      links {
        videoEmbed
        email
        behaviour
        file {
          localFile {
            publicURL
          }
        }
        link {
          title
          url
        }
        type
        icon
      }
    }
  }
  fragment coreCopyStackTwoColumnStatsGridAlt on WpPage_Page_FlexibleContent_TwoColumnStatsGridAlt {
    coreCopyStack {
      subheading
      heading
      text
      links {
        videoEmbed
        email
        behaviour
        file {
          localFile {
            publicURL
          }
        }
        link {
          title
          url
        }
        type
        icon
      }
    }
  }
  fragment coreCopyStackExperiencesSlider on WpPage_Page_FlexibleContent_ExperiencesSlider {
    coreCopyStack {
      subheading
      heading
      text
      links {
        videoEmbed
        email
        behaviour
        file {
          localFile {
            publicURL
          }
        }
        link {
          title
          url
        }
        type
        icon
      }
    }
  }
  fragment coreCopyStackTextCardSection on WpPage_Page_FlexibleContent_TextCardSection {
    coreCopyStack {
      subheading
      heading
      text
      links {
        videoEmbed
        email
        behaviour
        file {
          localFile {
            publicURL
          }
        }
        link {
          title
          url
        }
        type
        icon
      }
    }
  }
  fragment coreCopyStackFullWidthCardsCards on WpPage_Page_FlexibleContent_FullWidthCards_fullCards {
    coreCopyStack {
      subheading
      heading
      text
      links {
        videoEmbed
        email
        behaviour
        file {
          localFile {
            publicURL
          }
        }
        link {
          title
          url
        }
        type
        icon
      }
    }
  }
  fragment coreCopyStackFlexibleNewsletter on WpPage_Page_FlexibleContent_FlexibleNewsletter {
    coreCopyStack {
      subheading
      heading
      text
      links {
        videoEmbed
        email
        behaviour
        file {
          localFile {
            publicURL
          }
        }
        link {
          title
          url
        }
        type
        icon
      }
    }
  }
  fragment coreCopyStackTwoColumnText on WpPage_Page_FlexibleContent_TwoColumnText {
    coreCopyStack {
      subheading
      heading
      text
      links {
        videoEmbed
        email
        behaviour
        file {
          localFile {
            publicURL
          }
        }
        link {
          title
          url
        }
        type
        icon
      }
    }
  }
  fragment coreCopyStackTwoColumnIntro on WpPage_Page_FlexibleContent_TwoColumnIntro {
    coreCopyStack {
      subheading
      heading
      text
      links {
        videoEmbed
        email
        behaviour
        file {
          localFile {
            publicURL
          }
        }
        link {
          title
          url
        }
        type
        icon
      }
    }
  }
  fragment coreCopyStackMeetings on WpPage_Page_FlexibleContent_Meetings {
    coreCopyStack {
      subheading
      heading
      text
      links {
        videoEmbed
        email
        behaviour
        file {
          localFile {
            publicURL
          }
        }
        link {
          title
          url
        }
        type
        icon
      }
    }
  }
  fragment coreCopyStackBrands on WpPage_Page_FlexibleContent_Brands {
    coreCopyStack {
      subheading
      heading
      text
      links {
        videoEmbed
        email
        behaviour
        file {
          localFile {
            publicURL
          }
        }
        link {
          title
          url
        }
        type
        icon
      }
    }
  }
`

// Secondary Copy Stack

export interface SecondaryCopyStackProps {
  subheading: string
  heading: string
  text: string
}

export const SecondaryCopyStack = ({
  subheading,
  heading,
  text,
}: SecondaryCopyStackProps) => (
  <Fragment>
    {subheading && <SecondarySubheading>{subheading}</SecondarySubheading>}
    {heading && (
      <div
        className={`${subheading ? "mt-[16px]" : ""}${
          text ? " mb-[16px] lg:min-h-[75px]" : ""
        }`}
      >
        <SecondaryHeading>{heading}</SecondaryHeading>
      </div>
    )}
    {text && <BodyCopy size={BodyCopySizeOption.Small}>{text}</BodyCopy>}
  </Fragment>
)

export const SecondaryCopyStackFragment = graphql`
  fragment secondaryCopyStackTwoColumnGridList on WpPage_Page_FlexibleContent_TwoColumnGridList_list {
    secondaryCopyStack {
      subheading
      heading
      text
    }
  }
`
