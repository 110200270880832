import React, { Fragment } from "react"
import { Link as GLink } from "gatsby"
import { Icon, IconType } from "~/components/FlexibleLayouts/elements/Icons"
import VideoModal from "~/components/VideoModal"

export enum LinkType {
  Text = "text",
  Button = "button",
  Outline = "outlineButton",
  Dark = "dark",
}
export enum behaviourType {
  Internal = "internal",
  External = "external",
  Download = "download",
  Video = "video",
}

export interface LinkProps {
  link: {
    title: string
    url: string
  }
  type: LinkType
  icon: IconType
  behaviour: behaviourType
  file: any
  videoEmbed: string
  email: string
  className: string
}

export const Link = ({
  link,
  type,
  icon,
  behaviour,
  file,
  email,
  videoEmbed,
  className,
}: LinkProps) => {
  if (!link) return null

  // Main link classes

  let typeClasses = ""
  if (type === LinkType.Button) {
    typeClasses =
      "bg-beige hover:bg-light-grey transition-colors text-black py-[18px] px-[28px] md:py-[24px] md:px-[34px] rounded-[30px]"
  } else if (type === LinkType.Outline) {
    typeClasses =
      "bg-transparent hover:bg-white transition-colors text-white hover:text-black py-[18px] px-[28px] md:py-[24px] md:px-[34px] border border-white border-opacity-[33%] rounded-[30px]"
  } else if (type === LinkType.Dark) {
    typeClasses =
      "bg-slate hover:bg-white transition-colors text-white hover:text-slate py-[18px] px-[28px] md:py-[24px] md:px-[34px] rounded-[30px]"
  } else {
    typeClasses = "hover:opacity-50 transition-opacity"
  }

  // Animation classes for arrows

  let iconAnimationClasses = ""
  if (icon === IconType.ArrowRight) {
    iconAnimationClasses = "u-animate-button-arrow"
  } else if (icon === IconType.ArrowDown) {
    iconAnimationClasses = "u-animate-button-arrow-down"
  }

  return (
    <>
      {behaviour === "internal" ? (
        <GLink
          className={`inline-block font-semibold text-[14px] leading-[14px] md:text-[15px] md:leading-[15px] ${className} ${typeClasses} ${iconAnimationClasses}`}
          to={link.url}
        >
          {link.title}
          {icon && icon.type !== IconType.None && (
            <span
              className={`u-animate-target inline-block ml-2 relative top-[1px]`}
            >
              <Icon type={icon} />
            </span>
          )}
        </GLink>
      ) : behaviour === "video" ? (
        <VideoModal
          button
          title={link?.title}
          //   videoEmbed={videoEmbed}
          channel="custom"
          customVideo={videoEmbed}
        />
      ) : (
        <a
          className={`inline-block font-semibold text-[14px] leading-[14px] md:text-[15px] md:leading-[15px] ${className} ${typeClasses} ${iconAnimationClasses}`}
          href={
            behaviour === "download"
              ? file?.localFile?.publicURL
              : behaviour === "email"
              ? `mailto:${email}`
              : link.url
          }
          download={behaviour === "download" ? true : false}
        >
          {link.title}
          {icon && icon.type !== IconType.None && (
            <span
              className={`u-animate-target inline-block ml-2 relative top-[1px]`}
            >
              <Icon type={icon} />
            </span>
          )}
        </a>
      )}
    </>
  )
}

export interface LinksProps {
  links: LinkProps[]
  className: string
}

export const Links = ({ links, className }: LinksProps) => (
  <div
    className={`u-button-stack md:space-x-6 ${
      links.length > 2 ? "lg:space-x-6" : "lg:space-x-12"
    }  md:space-y-6 pt-[10px] pb-[10px] `}
  >
    {links &&
      links.map((link, i) => <Link key={i} {...link} className={className} />)}
  </div>
)
