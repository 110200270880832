import React, { useEffect, useRef } from "react"
import ImageStarfield from "../assets/js/image-starfield.js"
import useOptions from "~/hooks/useOptions"

function StarField() {
  const canvas = useRef()

  const { starfieldImages } = useOptions();

  useEffect(() => {
    
    const positions = [
      {
        width: 300,
        height: 200,
        position: {
          x: -450,
          y: -350,
          z: 500,
        },
      },
      {
        width: 278,
        height: 333,
        position: {
          x: -375,
          y: 50,
          z: 250,
        },
      },
      {
        width: 300,
        height: 200,
        position: {
          x: 200,
          y: 100,
          z: 0,
        },
      },
      {
        width: 300,
        height: 200,
        position: {
          x: 250,
          y: -360,
          z: 250,
        },
      },
      {
        width: 300,
        height: 200,
        position: {
          x: 250,
          y: -320,
          z: 250,
        },
      },

      {
        width: 300,
        height: 200,
        position: {
          x: -320,
          y: -420,
          z: 1200,
        },
      },
      {
        width: 278,
        height: 333,
        position: {
          x: -325,
          y: 100,
          z: 1250,
        },
      },
      {
        width: 300,
        height: 200,
        position: {
          x: 250,
          y: 150,
          z: 1000,
        },
      },
      {
        width: 300,
        height: 200,
        position: {
          x: 320,
          y: 310,
          z: 1250,
        },
      },
      {
        width: 300,
        height: 200,
        position: {
          x: 200,
          y: -260,
          z: 1250,
        },
      },

    ];

    const images_with_positions = starfieldImages.map((image, i) => {
      const position = positions[(i + 1 % positions.length - 1)];
      const row = Math.floor(i / positions.length);
      if(!position) return false;
      return {
        url: image.localFile?.publicURL,
        ...position
      }
    }).filter(image => image);

    new ImageStarfield(canvas.current, images_with_positions)

    return () => {
      // TODO: Avoid too many contexts
      return
    }
  }, [])
  return (
    <div className="h-full">
      <canvas
        width="1440px"
        height="800px"
        ref={canvas}
        className="w-full h-full bg-black "
      ></canvas>
    </div>
  )
}

export default StarField
