import React from "react"

export const ArrowRightIcon = () => (
  <svg
    width="13"
    height="12"
    viewBox="0 0 13 12"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path fill="currentColor" d="M0 5.08008H10.9673V6.6226H0V5.08008Z" />
    <path
      fill="currentColor"
      d="M7.88265 11.0206L6.8916 9.94018L10.6422 5.85209L6.8916 1.76401L7.88265 0.683594L12.623 5.85211L7.88265 11.0206Z"
    />
  </svg>
)

export const ArrowDownIcon = () => (
  <svg
    width="11"
    height="14"
    viewBox="0 0 11 14"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="currentColor"
      d="M6.08301 0.757812L6.08301 11.7251L4.54049 11.7251L4.54049 0.757812L6.08301 0.757812Z"
    />
    <path
      fill="currentColor"
      d="M0.143472 8.64144L1.22388 7.65039L5.31197 11.401L9.40006 7.65039L10.4805 8.64144L5.31195 13.3818L0.143472 8.64144Z"
    />
  </svg>
)

export const ArrowUpRightIcon = () => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.27637 20.418L18.4351 7.25924L20.2858 9.10998L7.1271 22.2687L5.27637 20.418Z"
      fill="#948A85"
    />
    <path
      d="M21.8572 18.0884L19.3718 17.9812L18.9669 8.57616L9.56192 8.17129L9.4547 5.68592L21.3435 6.19964L21.8572 18.0884Z"
      fill="#948A85"
    />
  </svg>
)

export const CursorIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.9">
      <path d="M10 14.2109L10 20.0005" stroke="white" strokeWidth="1.5" />
      <path d="M10 0L10 5.78952" stroke="white" strokeWidth="1.5" />
      <path d="M14.2109 10L20.0005 10" stroke="white" strokeWidth="1.5" />
      <path d="M0 10L5.78952 10" stroke="white" strokeWidth="1.5" />
      <circle cx="9.99986" cy="10.0008" r="1.57896" fill="white" />
    </g>
  </svg>
)

export const BookIcon = () => (
  <svg
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M32 12V58"
      stroke="#948A85"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M47 6C38.7 6 32 9.1 32 13.6C32 9.1 25.3 6 17 6C8.7 6 2 9.1 2 13.6V60C2 55.5 8.7 52 17 52C25.3 52 32 55.5 32 60C32 55.5 38.7 52 47 52C55.3 52 62 55.5 62 60V13.6C62 9.1 55.3 6 47 6Z"
      stroke="#948A85"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
  </svg>
)

export const BooksIcon = () => (
  <svg
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 16V28"
      stroke="#948A85"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path d="M2 44H18" stroke="#948A85" strokeWidth="2" strokeMiterlimit="10" />
    <path
      d="M26 16V28"
      stroke="#948A85"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M18 44H34"
      stroke="#948A85"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M43.9971 15.3027L46.3451 27.0717"
      stroke="#948A85"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M41.6299 44.3283L57.3209 41.1973"
      stroke="#948A85"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M2 5V48.75V61H18V48.75V5H2Z"
      stroke="#948A85"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M18 5V48.75V61H34V48.75V5H18Z"
      stroke="#948A85"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M34 6.08117L42.559 48.9862L44.956 60.9992L60.647 57.8692L58.25 45.8552L49.691 2.95117L34 6.08117Z"
      stroke="#948A85"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
  </svg>
)

export const TransactionsIcon = () => (
  <svg
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M58.9219 37.056C58.9739 36.3773 58.9999 35.692 58.9999 35C58.9995 28.8753 56.9169 22.9328 53.0938 18.148C49.2707 13.3631 43.9344 10.0201 37.9609 8.66797"
      stroke="#948A85"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M32 14C35.3137 14 38 11.3137 38 8C38 4.68629 35.3137 2 32 2C28.6863 2 26 4.68629 26 8C26 11.3137 28.6863 14 32 14Z"
      stroke="#948A85"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M20.314 10.6855C14.4786 13.4875 9.84414 18.2926 7.25494 24.2254C4.66575 30.1582 4.29419 36.8237 6.20796 43.0075"
      stroke="#948A85"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M32 14C35.3137 14 38 11.3137 38 8C38 4.68629 35.3137 2 32 2C28.6863 2 26 4.68629 26 8C26 11.3137 28.6863 14 32 14Z"
      stroke="#948A85"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M16.7783 57.2882C22.1222 60.945 28.6034 62.5569 35.0377 61.8295C41.472 61.1021 47.4298 58.0838 51.8223 53.3262"
      stroke="#948A85"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M8.61719 54.5C11.9309 54.5 14.6172 51.8137 14.6172 48.5C14.6172 45.1863 11.9309 42.5 8.61719 42.5C5.30348 42.5 2.61719 45.1863 2.61719 48.5C2.61719 51.8137 5.30348 54.5 8.61719 54.5Z"
      stroke="#948A85"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M55.3828 54.5C58.6965 54.5 61.3828 51.8137 61.3828 48.5C61.3828 45.1863 58.6965 42.5 55.3828 42.5C52.0691 42.5 49.3828 45.1863 49.3828 48.5C49.3828 51.8137 52.0691 54.5 55.3828 54.5Z"
      stroke="#948A85"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
  </svg>
)

export const DataIcon = () => (
  <svg
    width="54"
    height="62"
    viewBox="0 0 54 62"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 9V53C1 57.6 13.1 61 27 61C40.9 61 53 57.6 53 53V9"
      stroke="#948A85"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M53 9C53 13.6 40.9 17 27 17C13.1 17 1 13.6 1 9C1 4.4 13.1 1 27 1C40.9 1 53 4.4 53 9Z"
      stroke="#948A85"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M53 31C53 35.6 40.9 39 27 39C13.1 39 1 35.6 1 31"
      stroke="#948A85"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
  </svg>
)

export const GavelIcon = () => (
  <svg
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20 29L44 5"
      stroke="#948A85"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M54 15L30 39"
      stroke="#948A85"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M45 24L61 40L55 46L39 30"
      stroke="#948A85"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M32 41L18 27"
      stroke="#948A85"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M42 3L56 17"
      stroke="#948A85"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M40 62H2"
      stroke="#948A85"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M7 57V52H35V57"
      stroke="#948A85"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M2 44H9"
      stroke="#948A85"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M7.85742 29.8574L12.8074 34.8074"
      stroke="#948A85"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
  </svg>
)

export const ScaleIcon = () => (
  <svg
    width="57"
    height="57"
    viewBox="0 0 57 57"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0)">
      <path
        d="M28.5 28.5L46.3125 10.6875"
        stroke="#948A85"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M1.78125 35.625L21.375 35.625L21.375 55.2188"
        stroke="#948A85"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M1.78125 55.2187L55.2188 55.2188L55.2188 1.78125L1.78125 1.78125L1.78125 55.2187Z"
        stroke="#948A85"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M46.3125 24.9375L46.3125 10.6875L32.0625 10.6875"
        stroke="#948A85"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect
          width="57"
          height="57"
          fill="white"
          transform="translate(57 57) rotate(-180)"
        />
      </clipPath>
    </defs>
  </svg>
)

export const VideoIcon = () => (
  <svg
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M61 13V51C61 52.3261 60.4732 53.5979 59.5355 54.5355C58.5979 55.4732 57.3261 56 56 56H8C6.67392 56 5.40215 55.4732 4.46447 54.5355C3.52678 53.5979 3 52.3261 3 51V13C3 11.6739 3.52678 10.4021 4.46447 9.46447C5.40215 8.52678 6.67392 8 8 8H56C57.3261 8 58.5979 8.52678 59.5355 9.46447C60.4732 10.4021 61 11.6739 61 13V13Z"
      stroke="#948A85"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M55 14H9V50H55V14Z"
      stroke="#948A85"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M25 22L41 32L25 42V22Z"
      stroke="#948A85"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
  </svg>
)

export const GlobeIcon = () => (
  <svg
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M32 54V62"
      stroke="#948A85"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M13.234 46.7656L8 51.9996"
      stroke="#948A85"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M57.9996 2L52.7656 7.234"
      stroke="#948A85"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M48.0021 3.01953C55.2551 8.07953 60.0001 16.4855 60.0001 25.9995C60.0001 41.4635 47.4641 53.9995 32.0001 53.9995C21.6371 53.9995 12.5881 48.3695 7.74707 40.0005"
      stroke="#948A85"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M20 62H44"
      stroke="#948A85"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M32 47C43.598 47 53 37.598 53 26C53 14.402 43.598 5 32 5C20.402 5 11 14.402 11 26C11 37.598 20.402 47 32 47Z"
      stroke="#948A85"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
  </svg>
)

export const ContextualIcon = () => (
  <svg
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M39.0707 16.9289C37.1954 15.0535 34.6518 14 31.9997 14C29.3476 14 26.8041 15.0535 24.9287 16.9289"
      stroke="#948A85"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M46.1424 9.85773C42.3917 6.10708 37.3047 4 32.0004 4C26.6961 4 21.6091 6.10708 17.8584 9.85773"
      stroke="#948A85"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M4 16C8.24346 16 12.3131 17.6857 15.3137 20.6863C18.3143 23.6869 20 27.7565 20 32V34L24 43L20 45V48C20 49.5913 19.3679 51.1174 18.2426 52.2426C17.1174 53.3679 15.5913 54 14 54H8V60"
      stroke="#948A85"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M60 16C55.7565 16 51.6869 17.6857 48.6863 20.6863C45.6857 23.6869 44 27.7565 44 32V34L40 43L44 45V48C44 49.5913 44.6321 51.1174 45.7574 52.2426C46.8826 53.3679 48.4087 54 50 54H56V60"
      stroke="#948A85"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M32 26C32.5523 26 33 25.5523 33 25C33 24.4477 32.5523 24 32 24C31.4477 24 31 24.4477 31 25C31 25.5523 31.4477 26 32 26Z"
      stroke="#948A85"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
  </svg>
)

export const ShoppingIcon = () => (
  <svg
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.94527 55.2L12.0003 18H52.0003L58.0553 55.2C58.1712 55.9149 58.1303 56.6464 57.9357 57.344C57.741 58.0416 57.3971 58.6886 56.9278 59.2401C56.4584 59.7917 55.8749 60.2348 55.2175 60.5386C54.5601 60.8425 53.8445 60.9999 53.1203 61H10.8803C10.156 60.9999 9.44047 60.8425 8.78306 60.5386C8.12566 60.2348 7.54211 59.7917 7.07278 59.2401C6.60346 58.6886 6.25956 58.0416 6.06489 57.344C5.87021 56.6464 5.8294 55.9149 5.94527 55.2V55.2Z"
      stroke="#948A85"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M22 28V13C22 10.3478 23.0536 7.8043 24.9289 5.92893C26.8043 4.05357 29.3478 3 32 3V3C34.6522 3 37.1957 4.05357 39.0711 5.92893C40.9464 7.8043 42 10.3478 42 13V28"
      stroke="#948A85"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
  </svg>
)

export const OnboardingIcon = () => (
  <svg
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5 5L32 32V59"
      stroke="#948A85"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M37 27L59 5"
      stroke="#948A85"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M5 22V5H22"
      stroke="#948A85"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M59 21.999V5H41.999"
      stroke="#948A85"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
  </svg>
)

export const CogIcon = () => (
  <svg
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M32 41C36.9706 41 41 36.9706 41 32C41 27.0294 36.9706 23 32 23C27.0294 23 23 27.0294 23 32C23 36.9706 27.0294 41 32 41Z"
      stroke="#948A85"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M53.091 32C53.0869 30.4811 52.9192 28.967 52.591 27.484L59.746 22.066L54.474 12.934L46.188 16.434C43.9457 14.3759 41.2834 12.829 38.385 11.9L37.273 3H26.727L25.615 11.9C22.7153 12.8279 20.0517 14.3741 17.808 16.432L9.522 12.932L4.25 22.066L11.405 27.484C10.744 30.4584 10.744 33.5416 11.405 36.516L4.25 41.934L9.522 51.066L17.808 47.566C20.0515 49.6246 22.7152 51.1715 25.615 52.1L26.727 61H37.273L38.385 52.1C41.2846 51.1721 43.9483 49.6259 46.192 47.568L54.478 51.068L59.75 41.936L52.6 36.516C52.9252 35.0326 53.0898 33.5186 53.091 32V32Z"
      stroke="#948A85"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
  </svg>
)

export const TagIcon = () => (
  <svg
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28 62H14C11.791 62 10 60.209 10 58V24L30 4L50 24V36"
      stroke="#948A85"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M30 28C32.2091 28 34 26.2091 34 24C34 21.7909 32.2091 20 30 20C27.7909 20 26 21.7909 26 24C26 26.2091 27.7909 28 30 28Z"
      stroke="#948A85"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M32 52L40 60L56 44"
      stroke="#948A85"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
  </svg>
)

export const PerformanceIcon = () => (
  <svg
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M60 18L34 46L18 28L4 42"
      stroke="#948A85"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M40 18H60V38"
      stroke="#948A85"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
  </svg>
)

export const Caret = () => (
  <svg
    width="12"
    height="8"
    viewBox="0 0 12 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.00039 0.598437L6.00039 4.59844L10.0004 0.598438L11.4004 1.99844L6.00039 7.39844L0.600392 1.99844L2.00039 0.598437Z"
      fill="black"
    />
  </svg>
)

export enum IconType {
  None = "none",
  ArrowRight = "arrow-right",
  ArrowDown = "arrow-down",
  ArrowUpRight = "arrow-up-right",
  Cursor = "cursor",
  Book = "book",
  Books = "books",
  Transactions = "transactions",
  Data = "data",
  Gavel = "gavel",
  Scale = "scale",
  Video = "video",
  Globe = "globe",
  Contextual = "contextual",
  Shopping = "shopping",
  Onboarding = "onboarding",
  Cog = "cog",
  Tag = "tag",
  Performance = "performance",
  Caret = "caret",
}

const IconMap = {
  "arrow-right": ArrowRightIcon,
  "arrow-down": ArrowDownIcon,
  "arrow-up-right": ArrowUpRightIcon,
  cursor: CursorIcon,
  book: BookIcon,
  books: BooksIcon,
  transactions: TransactionsIcon,
  data: DataIcon,
  gavel: GavelIcon,
  scale: ScaleIcon,
  video: VideoIcon,
  globe: GlobeIcon,
  contextual: ContextualIcon,
  shopping: ShoppingIcon,
  onboarding: OnboardingIcon,
  cog: CogIcon,
  tag: TagIcon,
  performance: PerformanceIcon,
  caret: Caret,
}

interface IconProps {
  type: string
}

export const Icon = ({ type }: IconProps) => {
  if (type === IconType.None) return null
  return IconMap[type]()
}
