import React, { useState } from "react"
import AnimatedDownload from "../animations/AnimatedDownload"

function DownloadButton({
  downloadText,
  file,
  className,
  labelClass = "",
  ...other
}) {
  const [onHover, setOnHover] = useState(false)
  return (
    <a
      onMouseEnter={() => setOnHover(true)}
      onMouseLeave={() => setOnHover(false)}
      download
      href={
        file?.localFile?.publicURL ||
        file?.download?.localFile?.publicURL ||
        "#"
      }
      className={`flex items-center ${className}`}
      {...other}
    >
      <AnimatedDownload onHover={onHover} />
      <div className={`ml-4 font-semibold ${labelClass}`}>
        {downloadText || "Download"}
      </div>
    </a>
  )
}

export default DownloadButton
