import { motion } from "framer-motion"
import React, { useState } from "react"

function LoadMoreButton({ label = "Load more", clear, className, ...other }) {
  const [onHover, setOnHover] = useState(false)
  return (
    <div
      className={`c-btn cursor-pointer ${
        clear
          ? "c-btn--outline !border-slate !border-opacity-30"
          : "c-btn--dark "
      } ${className} `}
      onMouseEnter={() => setOnHover(true)}
      onMouseLeave={() => setOnHover(false)}
      {...other}
    >
      <div className="flex overflow-hidden">
        <span className=" mr-7">{label}</span>
        <div className="block mt-[-11px]">
          <motion.div
            className="h-auto"
            initial={{ y: -20 }}
            animate={{ y: onHover ? 13 : -20 }}
            transition={{ duration: 0.4, ease: "easeInOut" }}
          >
            <svg
              width="11"
              height="13"
              viewBox="0 0 11 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.08301 0.0546875L6.08301 11.022L4.54049 11.022L4.54049 0.0546874L6.08301 0.0546875Z"
                fill="white"
              />
              <path
                d="M0.143472 7.93832L1.22388 6.94727L5.31197 10.6979L9.40006 6.94727L10.4805 7.93832L5.31195 12.6787L0.143472 7.93832Z"
                fill="white"
              />
            </svg>
          </motion.div>
          <motion.div
            className="h-auto"
            animate={{ y: onHover ? 20 : 0 }}
            transition={{ duration: 0.4, ease: "easeInOut" }}
          >
            <svg
              width="11"
              height="13"
              viewBox="0 0 11 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.08301 0.0546875L6.08301 11.022L4.54049 11.022L4.54049 0.0546874L6.08301 0.0546875Z"
                fill="white"
              />
              <path
                d="M0.143472 7.93832L1.22388 6.94727L5.31197 10.6979L9.40006 6.94727L10.4805 7.93832L5.31195 12.6787L0.143472 7.93832Z"
                fill="white"
              />
            </svg>
          </motion.div>
        </div>
      </div>
    </div>
  )
}

export default LoadMoreButton
