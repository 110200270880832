import React, { Fragment } from "react"
import { graphql } from "gatsby"
import StarField from "~/components/StarField"
import Image from "~/components/Image"

export enum SectionDecorationType {
  None = "none",
  Starfield = "starfield",
  Image = "image",
  Icons = "icons",
}

export interface SectionProps {
  layoutName: string
  children: React.ReactNode
  isFirst: boolean
  isLast: boolean
  previousSection: SectionProps
  nextSection: SectionProps
  backgroundColour: string
  backgroundImage: any
  iconLeft: any
  iconRight: any
  sectionDecoration: SectionDecorationType
  noContainer: boolean
  paddingOverwrite: string
  id: string
}

export const Section = ({
  children,
  isFirst,
  isLast,
  previousSection,
  nextSection,
  layoutName = "default",
  backgroundColour = "white",
  backgroundImage = null,
  id,
  sectionDecoration = SectionDecorationType.None,
  noContainer = false,
  paddingOverwrite,
}: SectionProps) => {
  const paddingClasses = getSectionPaddingClasses(
    backgroundColour,
    layoutName,
    previousSection,
    nextSection
  )
  const colourClasses = getSectionColourClasses(backgroundColour)
  const [decorationClassesInner, decorationClassesOuter] =
    getSectionDecorationClasses(sectionDecoration)
  const layoutSpecificClasses = getLayoutSpecificClasses(layoutName)

  return (
    <section
      id={id}
      data-layout={layoutName}
      className={`${layoutSpecificClasses} ${paddingClasses} ${colourClasses} ${decorationClassesOuter} ${paddingOverwrite}`}
    >
      <Decoration
        sectionDecoration={sectionDecoration}
        backgroundImage={backgroundImage}
      />
      {noContainer ? (
        children
      ) : (
        <div className={"container " + decorationClassesInner}>{children}</div>
      )}
    </section>
  )
}

// Layout specific classes

function getLayoutSpecificClasses(layoutName: string) {
  let layoutSpecificClasses = ""
  if (layoutName === "TextAndMediaColumns") {
    layoutSpecificClasses = "overflow-hidden"
  }
  if (layoutName === "ExploreCta") {
    layoutSpecificClasses = "overflow-hidden"
  }
  if (layoutName === "SliderHistorySlider") {
    layoutSpecificClasses = "overflow-hidden"
  }
  return layoutSpecificClasses
}

// Helper function to decide padding amounts on sections

function getSectionPaddingClasses(
  backgroundColour: string,
  layoutName: string,
  previousSection: SectionProps,
  nextSection: SectionProps
) {
  const previousBackground =
    previousSection && previousSection.backgroundColour
      ? previousSection.backgroundColour
      : null
  const nextBackground =
    nextSection && nextSection.backgroundColour
      ? nextSection.backgroundColour
      : null

  const previousName =
    previousSection && previousSection.layoutName
      ? previousSection.layoutName
      : null
  const nextName =
    nextSection && nextSection.layoutName ? nextSection.layoutName : null

  let topPaddingClass = "pt-[25px] md:pt-[45px] xl:pt-[95px]"
  let bottomPaddingClass = "pb-[25px] md:pb-[45px] xl:pb-[95px]"

  // If next or previous item is not of the same background colour add top or bottom padding
  if (previousBackground !== backgroundColour) {
    topPaddingClass = "pt-[70px] md:pt-[90px] xl:pt-[180px]"
  }
  if (nextBackground !== backgroundColour) {
    bottomPaddingClass = "pb-[70px] md:pb-[90px] xl:pb-[180px]"
  }
  if (!nextName && backgroundColour === "black") {
    bottomPaddingClass = ""
  }
  // For text and media columns don't set any padding when they are adjacent on desktop
  if (
    layoutName === "TextAndMediaColumns" &&
    previousName === "TextAndMediaColumns"
  ) {
    topPaddingClass = "pt-[25px] md:pt-0 lg:mt-[-120px]"
  }
  if (
    layoutName === "TextAndMediaColumns" &&
    nextName === "TextAndMediaColumns"
  ) {
    bottomPaddingClass = "pb-[25px] md:pb-0"
  }
  // Featured video has less padding
  if (layoutName === "FeaturedVideo") {
    topPaddingClass = "py-[40px] md:py-[90px] xl:py-[140px]"
    bottomPaddingClass = ""
  }
  if (layoutName === "ScrollingText") {
    topPaddingClass = "py-8 md:py-12"
    bottomPaddingClass = ""
  }
  if (layoutName === "DefaultHero") {
    topPaddingClass = "pt-[70px] md:pt-[90px] xl:pt-[220px]"
  }

  return `${topPaddingClass} ${bottomPaddingClass}`
}

// Helper function to decide colour classes by background colour

function getSectionColourClasses(backgroundColour: string) {
  let colourClasses = ""
  if (backgroundColour === "black") {
    colourClasses = "bg-black text-white"
  }
  switch (backgroundColour) {
    case "black":
      colourClasses = "bg-black text-white"
      break
    case "beige":
      colourClasses = "bg-beige"
      break
    case "slate":
      colourClasses = "bg-slate text-white"
      break
    case "light-grey":
      colourClasses = "bg-light-grey"
      break
  }
  return colourClasses
}

// Handle additional decorations

function getSectionDecorationClasses(sectionDecoration: SectionDecorationType) {
  let decorationClassesInner = ""
  let decorationClassesOuter = ""
  if (
    sectionDecoration === SectionDecorationType.Starfield ||
    sectionDecoration === SectionDecorationType.Image
  ) {
    decorationClassesInner = "relative"
    decorationClassesOuter = "relative"
  }
  return [decorationClassesInner, decorationClassesOuter]
}

export interface DecorationProps {
  sectionDecoration: SectionDecorationType
  backgroundImage: any
}

export const Decoration = ({
  sectionDecoration,
  backgroundImage,
}: DecorationProps) => {
  if (sectionDecoration === SectionDecorationType.None) return null

  if (sectionDecoration === SectionDecorationType.Starfield) {
    return (
      <div className="absolute top-0 bottom-0 left-0 right-0">
        <StarField />
      </div>
    )
  }

  if (sectionDecoration === SectionDecorationType.Image && backgroundImage) {
    return (
      <div className="absolute top-0 bottom-0 left-0 right-0">
        <Image
          objectFit="cover"
          image={backgroundImage}
          className="w-full h-full"
        />
      </div>
    )
  }

  return null
}

// Graphql fragments for all components with sections

export const SectionFragment = graphql`
  fragment sectionTwoColumnList on WpPage_Page_FlexibleContent_TwoColumnList {
    section {
      id
      backgroundColour
      backgroundImage {
        ...Image
      }
      sectionDecoration
    }
  }
  fragment sectionDefaultHero on WpPage_Page_FlexibleContent_DefaultHero {
    section {
      id
      backgroundColour
      backgroundImage {
        id
        altText
        localFile {
          url
          publicURL
          childImageSharp {
            gatsbyImageData(placeholder: NONE, quality: 100)
          }
        }
      }
      sectionDecoration
    }
  }
  fragment sectionCareersHero on WpPage_Page_FlexibleContent_CareersHero {
    section {
      id
      backgroundColour
      backgroundImage {
        ...Image
      }
      sectionDecoration
    }
  }
  fragment sectionTextCardSection on WpPage_Page_FlexibleContent_TextCardSection {
    section {
      id
      backgroundColour
      backgroundImage {
        ...Image
      }
      sectionDecoration
    }
  }
  fragment sectionFullWidthImage on WpPage_Page_FlexibleContent_FullWidthImage {
    section {
      id
      backgroundColour
      backgroundImage {
        ...Image
      }
      sectionDecoration
    }
  }
  fragment sectionJobListings on WpPage_Page_FlexibleContent_JobListings {
    section {
      id
      backgroundColour
      backgroundImage {
        ...Image
      }
      sectionDecoration
    }
  }
  fragment sectionLegalHero on WpPage_Page_FlexibleContent_LegalHero {
    section {
      id
      backgroundColour
      backgroundImage {
        ...Image
      }
      sectionDecoration
    }
  }
  fragment sectionFeaturedIconCards on WpPage_Page_FlexibleContent_FeaturedIconCards {
    section {
      id
      backgroundColour
      backgroundImage {
        ...Image
      }
      sectionDecoration
    }
  }
  fragment sectionTabbedSection on WpPage_Page_FlexibleContent_TabbedSection {
    section {
      id
      backgroundColour
      backgroundImage {
        ...Image
      }
      sectionDecoration
    }
  }
  fragment sectionHubCta on WpPage_Page_FlexibleContent_HubCta {
    section {
      id
      backgroundColour
      backgroundImage {
        ...Image
      }
      sectionDecoration
    }
  }
  fragment sectionAnchorNav on WpPage_Page_FlexibleContent_AnchorNav {
    section {
      id
      backgroundColour
      backgroundImage {
        ...Image
      }
      sectionDecoration
    }
  }
  fragment sectionSustainability on WpPage_Page_FlexibleContent_Sustainability {
    section {
      id
      backgroundColour
      backgroundImage {
        ...Image
      }
      sectionDecoration
    }
  }
  fragment sectionNumberedCards on WpPage_Page_FlexibleContent_NumberedCards {
    section {
      id
      backgroundColour
      backgroundImage {
        ...Image
      }
      sectionDecoration
    }
  }
  fragment sectionNewsPosts on WpPage_Page_FlexibleContent_NewsPosts {
    section {
      id
      backgroundColour
      backgroundImage {
        ...Image
      }
      sectionDecoration
    }
  }
  fragment sectionTrackRecord on WpPage_Page_FlexibleContent_TrackRecord {
    section {
      id
      backgroundColour
      backgroundImage {
        ...Image
      }
      sectionDecoration
    }
  }
  fragment sectionHighlights on WpPage_Page_FlexibleContent_Highlights {
    section {
      id
      backgroundColour
      backgroundImage {
        ...Image
      }
      sectionDecoration
    }
  }
  fragment sectionListSwitcher on WpPage_Page_FlexibleContent_ListSwitcher {
    section {
      id
      backgroundColour
      backgroundImage {
        ...Image
      }
      sectionDecoration
    }
  }
  fragment sectionTwoColumnGridList on WpPage_Page_FlexibleContent_TwoColumnGridList {
    section {
      id
      backgroundColour
      backgroundImage {
        ...Image
      }
      sectionDecoration
    }
  }
  fragment sectionTextCta on WpPage_Page_FlexibleContent_TextCta {
    section {
      id
      backgroundColour
      backgroundImage {
        ...Image
      }
      sectionDecoration
    }
  }
  fragment sectionTextAndMediaColumns on WpPage_Page_FlexibleContent_TextAndMediaColumns {
    section {
      id
      backgroundColour
      backgroundImage {
        ...Image
      }
      sectionDecoration
    }
  }
  fragment sectionTextAndMediaColumnsContained on WpPage_Page_FlexibleContent_TextAndMediaColumnsContained {
    section {
      id
      backgroundColour
      backgroundImage {
        ...Image
      }
      sectionDecoration
    }
  }
  fragment sectionExploreCta on WpPage_Page_FlexibleContent_ExploreCta {
    section {
      id
      backgroundColour
      backgroundImage {
        ...Image
      }
      sectionDecoration
    }
  }
  fragment sectionTwoColumnStatsGrid on WpPage_Page_FlexibleContent_TwoColumnStatsGrid {
    section {
      id
      backgroundColour
      backgroundImage {
        ...Image
      }
      sectionDecoration
    }
  }
  fragment sectionFlexibleNewsletter on WpPage_Page_FlexibleContent_FlexibleNewsletter {
    section {
      id
      backgroundColour
      backgroundImage {
        ...Image
      }
      sectionDecoration
    }
  }
  fragment sectionEventsSection on WpPage_Page_FlexibleContent_EventsSection {
    section {
      id
      backgroundColour
      backgroundImage {
        ...Image
      }
      sectionDecoration
    }
  }

  fragment sectionDownloadList on WpPage_Page_FlexibleContent_DownloadList {
    section {
      id
      id
      backgroundColour
      backgroundImage {
        ...Image
      }
      sectionDecoration
    }
  }
  fragment sectionDownloadGrid on WpPage_Page_FlexibleContent_DownloadGrid {
    section {
      id
      backgroundColour
      backgroundImage {
        ...Image
      }
      sectionDecoration
    }
  }
  fragment sectionTwoColumnStatsGridAlt on WpPage_Page_FlexibleContent_TwoColumnStatsGridAlt {
    section {
      id
      backgroundColour
      backgroundImage {
        ...Image
      }
      sectionDecoration
    }
  }
  fragment sectionFullWidthCards on WpPage_Page_FlexibleContent_FullWidthCards {
    section {
      id
      backgroundColour
      backgroundImage {
        ...Image
      }
      sectionDecoration
    }
  }
  fragment sectionMidpageHero on WpPage_Page_FlexibleContent_MidpageHero {
    section {
      id
      backgroundColour
      backgroundImage {
        ...Image
      }
      sectionDecoration
    }
  }
  fragment sectionFeaturedVideo on WpPage_Page_FlexibleContent_FeaturedVideo {
    section {
      id
      backgroundColour
      backgroundImage {
        ...Image
      }
      sectionDecoration
    }
  }
  fragment sectionLogoGrid on WpPage_Page_FlexibleContent_LogoGrid {
    section {
      id
      backgroundColour
      backgroundImage {
        ...Image
      }
      sectionDecoration
    }
  }
  fragment sectionSliderHistorySlider on WpPage_Page_FlexibleContent_SliderHistorySlider {
    section {
      id
      backgroundColour
      backgroundImage {
        ...Image
      }
      sectionDecoration
    }
  }
  fragment sectionPeopleSlider on WpPage_Page_FlexibleContent_PeopleSlider {
    section {
      id
      backgroundColour
      backgroundImage {
        ...Image
      }
      sectionDecoration
    }
  }
  fragment sectionStaggeredCards on WpPage_Page_FlexibleContent_StaggeredCards {
    section {
      id
      backgroundColour
      backgroundImage {
        ...Image
      }
      sectionDecoration
    }
  }
  fragment sectionTeamMembers on WpPage_Page_FlexibleContent_TeamMembers {
    section {
      id
      backgroundColour
      backgroundImage {
        ...Image
      }
      sectionDecoration
    }
  }
  fragment sectionOffices on WpPage_Page_FlexibleContent_Offices {
    section {
      backgroundColour
      backgroundImage {
        ...Image
      }

      sectionDecoration
    }
  }
  fragment sectionContacts on WpPage_Page_FlexibleContent_Contacts {
    section {
      backgroundColour
      backgroundImage {
        ...Image
      }

      sectionDecoration
    }
  }
  fragment sectionHomeHero on WpPage_Page_FlexibleContent_HomeHero {
    section {
      id
      backgroundColour
      backgroundImage {
        ...Image
      }
      sectionDecoration
    }
  }
  fragment sectionScrollingText on WpPage_Page_FlexibleContent_ScrollingText {
    section {
      id
      backgroundColour
      backgroundImage {
        ...Image
      }
      sectionDecoration
    }
  }
  fragment sectionStaggeredTypographyCards on WpPage_Page_FlexibleContent_StaggeredTypographyCards {
    section {
      id
      backgroundColour
      backgroundImage {
        ...Image
      }
      sectionDecoration
    }
  }
  fragment sectionFeaturedNews on WpPage_Page_FlexibleContent_FeaturedNews {
    section {
      id
      backgroundColour
      backgroundImage {
        ...Image
      }
      sectionDecoration
    }
  }
  fragment sectionVennDiagram on WpPage_Page_FlexibleContent_VennDiagram {
    section {
      backgroundColour
      backgroundImage {
        ...Image
      }

      sectionDecoration
    }
  }
  fragment sectionExperiencesSlider on WpPage_Page_FlexibleContent_ExperiencesSlider {
    section {
      backgroundColour
      backgroundImage {
        ...Image
      }

      sectionDecoration
    }
  }
  fragment sectionTextWSidebar on WpPage_Page_FlexibleContent_TextWSidebar {
    section {
      backgroundColour
      backgroundImage {
        ...Image
      }

      sectionDecoration
    }
  }
  fragment sectionTwoColumnTextAlt on WpPage_Page_FlexibleContent_TwoColumnTextAlt {
    section {
      id
      backgroundColour
      backgroundImage {
        ...Image
      }
      sectionDecoration
    }
  }
  fragment sectionTwoColumnText on WpPage_Page_FlexibleContent_TwoColumnText {
    section {
      id
      backgroundColour
      backgroundImage {
        ...Image
      }
      sectionDecoration
    }
  }
  fragment sectionTwoColumnIntro on WpPage_Page_FlexibleContent_TwoColumnIntro {
    section {
      id
      backgroundColour
      backgroundImage {
        ...Image
      }
      sectionDecoration
    }
  }
  fragment sectionCardSlider on WpPage_Page_FlexibleContent_CardSlider {
    section {
      id
      backgroundColour
      backgroundImage {
        ...Image
      }
      sectionDecoration
    }
  }
  fragment sectionMeetings on WpPage_Page_FlexibleContent_Meetings {
    section {
      id
      backgroundColour
      backgroundImage {
        ...Image
      }
      sectionDecoration
    }
  }
  fragment sectionBrands on WpPage_Page_FlexibleContent_Brands {
    section {
      id
      backgroundColour
      backgroundImage {
        ...Image
      }
      sectionDecoration
    }
  }
  fragment sectionPartnerCta on WpOptions_Options_PartnerCta {
    section {
      id
      backgroundColour
      backgroundImage {
        ...Image
      }
      sectionDecoration
    }
  }
`
